/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ========================  Jacobi Styles  ======================== */

@import "./global-sass-variables.scss";


.treelove-marker {
    background-image: url('./assets/img/treelove-marker.png');
    background-size: cover;
    width: 30px;
    height: 35px;
    // border-radius: 50%;
    cursor: pointer;
}

.inline-radio-col {
    display: inline-block;
}


a {
    text-decoration: underline;
}

.clickable {
    text-decoration: underline;
    cursor: pointer;
}
ion-button {
    background: none !important; //not sure why thisis necessary
    text-transform: none !important;
    font-size: 1.5rem !important;
    --border-width: 1px !important;
    --border-color: transparent !important;
    --border-radius: 10px !important;
    height: 3.5rem !important;
    border-color: linear-gradient(92.14deg, #04715D -10.27%, #68C08B 22.28%, #045546 98.76%) !important;
    --background: linear-gradient(92.14deg, #04715D -10.27%, #68C08B 22.28%, #045546 98.76%) !important;
    &::part(native) {
        border-radius: 10px;
    }
}

ion-button.small {
    font-size: 1rem !important;
    height: 2rem !important;
}



ion-card {
    border-radius: 10px;
}

.input-item {
    min-height: 3.5rem !important;
    height: 3.5rem !important;
    border: 2px solid #045546 !important;
    border-radius: 10px !important;
    --highlight-background: none;
    --highlight-height: 0px !important;
    
    ion-icon {
        margin: 0 !important;
        font-size: 2rem;
        align-self: center;
        color: #045546 !important;
    }
    ion-label {
        font-size: 1rem !important;
        margin: 0 !important;
        --color: #B1B1B1 !important;
        font-weight: 600;
    }
    ion-input {
        margin: 0 !important;
        color: #045546 !important;
    }
}

.bordered-item {
    min-height: 3.5rem !important;
    height: 3.5rem !important;
    border: 2px solid #045546 !important;
    border-radius: 10px !important;
    --highlight-background: none;
    --highlight-height: 0px !important;
    color: var(--ion-color-primary);
    ion-icon {
        color: var(--ion-color-primary);
        font-size: 2rem;
    }
}
.bordered-textarea-item {
    @extend .bordered-item;
    min-height: 7rem !important;
    height: 7rem !important; 
}

.input-item::part(native) {
    min-height: 3.5rem !important;
    height: 3.5rem !important;
    --background-hover-opacity: 0;
}

.quantity-selection-modal {
    .quantity-selection-quantity {
        font-size: 2rem !important;
    }
    .quantity-selection-button {
        width: 4rem !important;
        --padding-bottom: 0;
        --padding-bottom: 0;
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;

    }
}

.custom-modal::part(content) {
    height: var(--current-modal-height) !important;
    width: var(--current-modal-width) !important;
    // max-width: 50%;
    max-height: 100%;
    border-radius: var(--tl-border-radius) !important;
    overflow-y: scroll;
  }
  .custom-modal {
    .modal-wrapper {
        ion-button {
            background: rgb(66, 135, 245);
            width: 100%;
            color: white;
            margin-left: 0;
            margin-right: 0;
        }
        
      // height: 21rem;
      // max-width: 50%;
      color: var(--ion-color-primary);
      
      background: transparent;
      // height: 100%;
      --box-shadow: none;
  
      ion-card {
        margin: auto auto;
        box-shadow: var(--tl-box-shadow);
        padding: 0;
        border-radius: var(--tl-border-radius);
      }
  
      .logo-wrapper {
        .title {
            color: white;
            margin: 0.5rem;
            font-size: 2rem;
        }
        
        background: var(--ion-color-primary);
        padding: 0.5rem;
        
        position: relative;
        height: 4rem;
  
        ion-img {
          height: 3rem;
          float: left;
  
          &::part(image) {
            width: auto;
          }
        }
  
        .close-icon {
          position: absolute;
          color: white;
          font-size: 2rem;
          top: 1rem;
          right: 1rem;
        }
      }
  
      .modal-content-wrapper {
        margin: 2rem 0;
  
        .text-notice {
          text-align-last: center;
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
  
          p {
            text-align-last: center;
            font-size: 1.5rem;
            font-weight: bold;
  
            &:last-child {
              margin-bottom: 1rem;
            }
          }
        }
  
        .action-content-wrapper {
          padding: 2rem;
          margin: 0 auto;
          width: 20rem;
          background: white;
          text-align-last: center;
        }

        
      }
    }
}


// ================================ media queries
@include screen-md-and-below {
    ion-modal {

        &.custom-modal .modal-wrapper .modal-content-wrapper .text-notice p,
        &.custom-modal {
            p, p.text-notice, .text-notice p { font-size: 1rem; }
            h2 { font-size: 1.2rem; } 
            h1 { font-size: 2rem; }
            
            ion-card {
                width: 100%;

                .logo-wrapper {
                ion-img {
                    padding: 10px;
                }
                }
            }
            
        }

        &.custom-modal {
            width: 100%;
            padding: 0 0.5rem;
            
            .modal-wrapper {
                width: 100%;
            }
        }

        &.confirm-action-warning-modal {
            .text-notice {
                text-align: center;
                
                p {
                    margin-bottom: 2rem;
                }

                strong {
                    text-decoration: underline;
                }
            }
        }

        // ==========================   vendor settings
        &.upload-photo-modal {
            padding: 0;

            ion-card {
                width: 100%;
                height: 100%;
            }
        }
        &.change-avatar-modal {
            padding: 0;

            ion-card {
                width: 100%;
                height: 100%;
            }
        }
    }
}



@media (max-width: ($screen-sm - 1px)) {
    .hide-xs {
        display: none !important;
    }
}
@media (max-width: ($screen-md - 1px)) {
    .web {
        display: none !important;
    }
    .hide-sm-and-below {
        display: none !important;
    }
}
@media (max-width: ($screen-lg - 1px)) {
    .hide-md-and-below {
        display: none !important;
    }
}
@media (max-width: ($screen-xl - 1px)) {
    .hide-lg-and-below {
        display: none !important;
    }
}



@media (min-width: $screen-xl) {
    .hide-xl {
        display: none !important;
    }
}
@media (min-width: $screen-lg) {
    .hide-lg-and-above {
        display: none !important;
    }
}
@media (min-width: $screen-md) {
    .mobile {
        display: none !important;
    }
    .hide-md-and-above {
        display: none !important;
    }
}
@media (min-width: $screen-sm) {
    .hide-sm-and-above {
        display: none !important;
    }
}