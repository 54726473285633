$screen-mobile: 820px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

@mixin screen-sm-and-below {
    @media (max-width: calc($screen-sm - 1px)) {
      @content;
    }
  }
  
  @mixin screen-md-and-below {
    @media (max-width: calc($screen-md - 1px)) {
      @content;
    }
  }
  
  @mixin screen-lg-and-below {
    @media (max-width: calc($screen-lg - 1px)) {
      @content;
    }
  }
  
  @mixin screen-xl-and-below {
    @media (max-width: calc($screen-xl - 1px)) {
      @content;
    }
  }
  
  // min-width
  @mixin screen-sm-and-above {
    @media (min-width: $screen-sm) {
      @content;
    }
  }
  
  @mixin screen-md-and-above {
    @media (min-width: $screen-md) {
      @content;
    }
  }
  
  @mixin screen-lg-and-above {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
  
  @mixin screen-xl-and-above {
    @media (min-width: $screen-xl) {
      @content;
    }
  }
  
  // custom media query min/max
  @mixin screen-below-mobile-820 {
    @media (max-width: $screen-mobile) {
      @content;
    }
  }
  
  @mixin screen-above-mobile-820 {
    @media (min-width: $screen-mobile) {
      @content;
    }
  }
  
  // custom media query min/max
  @mixin screen-below-custom($custom-width: 820px) {
    @media (max-width: $custom-width) {
      @content;
    }
  }
  
  @mixin screen-above-custom($custom-width: 820px) {
    @media (min-width: $custom-width) {
      @content;
    }
  }



  